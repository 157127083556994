import React from 'react'
import {
    TextCommonProjectName,
    TextHeaderNavItemHome,
    TextHeaderNavItemQuiz,
    TextHeaderNavItemContact
}  from './../config/Text'
import {
    Link
} from "react-router-dom";
import logo from './../img/logo.png'

class Header extends React.Component
{

    mobileMenuOpen(event)
    {
        event.preventDefault()
        document.body.classList.add('mobile-menu-open')
    }

    mobileMenuClose(event)
    {
        event.preventDefault()
        document.body.classList.remove('mobile-menu-open')
    }

    render()
    {
        //// to header menu item with two lines add: className="linkDoubleLine" 
        return (
            <header>
                <div className="container">
                    <div className="row"> 
                        <h1 className="col-xl-5 col-lg-5 col-md-4">
                            <Link to="/">
                                <img src={logo} height="89" alt={TextCommonProjectName} />
                                {TextCommonProjectName}
                            </Link>
                            <button className="mobile-menu" onClick={this.mobileMenuOpen}>
                                <i className="fas fa-bars"></i>
                            </button>
                        </h1>
                        <nav className="col-xl-7 col-lg-7 col-md-8 menu">

                            <button className="mobile-menu-close" onClick={this.mobileMenuClose}>
                                <i className="fa fa-times"></i>
                            </button>
                            <Link to="/">
                                {TextHeaderNavItemHome}
                            </Link>
                            
                            <Link to="/quiz">
                                {TextHeaderNavItemQuiz}
                            </Link>
                            <Link to="/contact">
                                {TextHeaderNavItemContact}
                            </Link>
                        </nav>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header