import React from 'react'
import {Link} from 'react-router-dom'
import NumberFormat from 'react-number-format';
import axios from 'axios';
import {
    TextBannerAbout,
    TextBannerEconomy,
    TextBannerEconomy2,
    TextBannerLinkToQuiz
} from './../config/Text'
import BannerSlide from './../components/BannerSlide'

class  Banner extends React.Component
{

    constructor(props)
    {
        super(props)
        this.state = 
        {
            savings : ''
        }

        let api = new URL(process.env.REACT_APP_MIESZKAY_API_URL)
        api.pathname = 'get_savings'
        let self = this
        axios({
            url : api,
            method : 'POST'
        })
        .then( response => { 

            response.data.savings = Math.floor(response.data.savings)

            sessionStorage.setItem('user_economy', response.data.savings)

            self.setState({
                'savings' : response.data.savings
            })

        })

    }

    componentDidMount()
    {

        if( sessionStorage.getItem('user_economy') != null )
        {
            this.setState({
                'savings' : sessionStorage.getItem('user_economy')
            })
            return;
        }
    }

    render()
    {
        return (
            <>
            <section className="section-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7"></div>
                        <div className="col-md-5 overlay">
                            <p>
                                {TextBannerAbout}
                            </p>
                            <h4>
                                {TextBannerEconomy}
                                &nbsp;
                                <NumberFormat 
                                        value={this.state.savings}
                                        displayType={'text'} 
                                        thousandSeparator={''}
                                        prefix={''} 
                                        suffix=" PLN"/>
                                &nbsp;
                                {TextBannerEconomy2}
                            </h4>
                            <Link to="/quiz">
                                {TextBannerLinkToQuiz}
                            </Link>
                        </div>
                    </div>
                </div>
                <BannerSlide />
            </section>  
            <div className="col-md-5 overlay mobile-banner-about">
                <p>
                    {TextBannerAbout}
                </p>
                <h4>
                    {TextBannerEconomy}
                    &nbsp;
                    <NumberFormat 
                            value={this.state.savings}
                            displayType={'text'} 
                            thousandSeparator={''}
                            prefix={''} 
                            suffix=" PLN"/>
                    &nbsp;
                    {TextBannerEconomy2}
                </h4>
                <Link to="/quiz">
                    {TextBannerLinkToQuiz}
                </Link>
            </div>
            </>
        )
    }
}

export default Banner