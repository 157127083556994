import React from 'react'

import QuizNav from '../../components/QuizNav'

import ilustration from '../../img/quiz/quiz-step-5.png'

import {
    TextQuizStep5Title
} from '../../config/Text'

class QuizStep5 extends React.Component
{
    constructor(props)
    {
        super(props)
        let question5 = sessionStorage.getItem('question_5');
        if ( question5 == null )
        {
            question5 = '';
        }
        let _nextDisable = this.questionValueValidate()
        
        this.state = 
        {
            question5 : question5,
            nextDisable : _nextDisable
        }
        this.handleChange = this.handleChange.bind(this)
    }

    questionValueValidate()
    {
        return sessionStorage.getItem('question_5') === null ||
        sessionStorage.getItem('question_5') === '' ||
        parseInt(sessionStorage.getItem('question_5')) < 0 ||
        parseInt(sessionStorage.getItem('question_5')) > 50
    }

    handleChange(event)
    {        
        this.setState({ 
            question5: event.target.value,
            nextDisable : this.questionValueValidate()
        })

        sessionStorage.setItem('question_5', event.target.value)

    }

    render()
    {
        if ( this.props.step !== '5' )
        {
            return null;
        }

        return (
            <>
               <section className="page page-quiz" data-step={this.props.step}>
                    <span className="progress"></span>
                    <div className="container">
                       <div className="row">
                            <div className="col-md-7">

                                <h2>
                                    {TextQuizStep5Title}
                                </h2>

                                <input 
                                    type="number"
                                    min="1"
                                    max="20"
                                    name="question_5"
                                    value={this.state.question5}
                                    onChange={this.handleChange}
                                    onKeyUp={this.handleChange}
                                    />

                                <QuizNav 
                                    step={this.props.step} 
                                    nextDisable={this.state.nextDisable} 
                                    prevClick={this.props.prevClick} 
                                    nextClick={this.props.nextClick} />
                            </div>
                            <div className="col-md-5 illustration illustration-5">
                                <img src={ilustration} alt="" />
                            </div>

                        </div>
                   </div>
               </section>
            </>
        )
    }
}

export default QuizStep5