import React from 'react'
import axios from 'axios'

import NumberFormat from 'react-number-format';

import {
    TextUserEconomyBeforeAmount,
    TextUserEconomyAfterAmount
} from './../config/Text'

class UserEconomy extends React.Component
{

    constructor(props)
    {
        super(props)
        this.state = 
        {
            savings : ''
        }
    }

    componentDidMount()
    {

        if( sessionStorage.getItem('user_economy') != null )
        {
            this.setState({
                'savings' : sessionStorage.getItem('user_economy')
            })
            return;
        }

        let api = new URL(process.env.REACT_APP_MIESZKAY_API_URL)
        api.pathname = 'get_savings'
        let self = this
        axios({
            url : api,
            method : 'POST'
        })
        .then( response => { 

            response.data.savings = Math.floor(response.data.savings)

            sessionStorage.setItem('user_economy', response.data.savings)

            self.setState({
                'savings' : response.data.savings
            })

        })
    }

    render ()
    {
        return (
            <section className="section-user-economy">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <p>
                                {TextUserEconomyBeforeAmount} 
                                <span className="economyAmount">
                                    <NumberFormat 
                                        value={this.state.savings}
                                        displayType={'text'} 
                                        thousandSeparator={' '}
                                        prefix={''} 
                                        suffix=" PLN"/>
                                </span> 
                                {TextUserEconomyAfterAmount} 
                            </p>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            </section>
        )
    }

}

export default UserEconomy