import React from 'react'

import QuizNav from '../../components/QuizNav'

import ilustration from '../../img/quiz/quiz-step-0.png'

import {
    TextQuizStep0Tip,
    TextQuizStep0Title,
    TextQuizStep0_Option_0,
    TextQuizStep0_Option_1
} from '../../config/Text'

class QuizStep0 extends React.Component
{

    constructor(props)
    {
        super(props)
        this.state = 
        {
            nextDisable : sessionStorage.getItem('question_0') === null
        }
        this.changeInput = this.changeInput.bind(this)
    }

    changeInput(event)
    {
        this.setState({
            nextDisable : false
        })
        sessionStorage.setItem('question_0',event.target.value)
    }

    render()
    {
        if ( this.props.step !== '0' )
        {
            return null;
        }

        return (
            <>
               <section className="page page-quiz" data-step={this.props.step}>
                   <span className="progress"></span>
                   <div className="container">
                       <div className="row">
                            <div className="col-md-7">
                                <p>
                                    {TextQuizStep0Tip}
                                </p>

                                <h2>
                                    {TextQuizStep0Title}
                                </h2>

                                <input 
                                    type="radio"
                                    name="question_0"
                                    id="question0_a"
                                    checked={sessionStorage.getItem('question_0') === 'buy'}
                                    onChange={this.changeInput}
                                    value="buy"
                                />
                                <label htmlFor="question0_a">
                                    {TextQuizStep0_Option_0}
                                </label>

                                <input 
                                    type="radio"
                                    name="question_0"
                                    id="question0_b"
                                    checked={sessionStorage.getItem('question_0') === 'sell'}
                                    onChange={this.changeInput}
                                    value="sell"
                                />

                                <label htmlFor="question0_b">
                                    {TextQuizStep0_Option_1}
                                </label>
                                <QuizNav 
                                    step={this.props.step}
                                    nextDisable={this.state.nextDisable} 
                                    prevClick={this.props.prevClick} 
                                    nextClick={this.props.nextClick} />
                            </div>
                            <div className="col-md-5 illustration illustration-0">
                                <img src={ilustration} alt="" />
                            </div>
                        </div>
                   </div>
               </section>
            </>
        )
    }
}

export default QuizStep0