import React from 'react'
import {
    TextCommonProjectName,
    TextFooterNavItemPrivacyPolicy,
    TextFooterDevelopedBy,
    TextFooterDevelopedBySite,
    TextFooterDevelopedByName,
    TextFooterNavItemRegulations,
    TextFooterPhone,
    TextFooterMail
}  from './../config/Text'
import {
    Link
} from "react-router-dom";
import logo from '../img/logo-footer.png'

function Footer(){
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-lg-2 col-md-2">
                        <h6>
                            <img src={logo} alt={TextCommonProjectName} />
                            {TextCommonProjectName}
                        </h6>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4">
                        <Link to="/privacy-policy"> 
                            {TextFooterNavItemPrivacyPolicy}
                        </Link>
                        <p className="developedBy">
                            {TextFooterDevelopedBy}
                            <a href={`${TextFooterDevelopedBySite}?utm_source=Mieszkay`} rel="noopener noreferrer" target="_blank">
                                {TextFooterDevelopedByName}
                            </a> 
                        </p>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-2">
                        <Link to="/regulations">
                            {TextFooterNavItemRegulations}
                        </Link>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4">
                        <p>
                            <i className="fas fa-phone"></i>
                            {TextFooterPhone}
                        </p>
                        <p>
                            <i className="fas fa-at"></i>
                            <a href={"mailto:"+ TextFooterMail}>
                                {TextFooterMail}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer