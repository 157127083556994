import React from 'react'

import QuizNav from '../../components/QuizNav'

import ilustration from '../../img/quiz/quiz-step-3.png'

import {
    TextQuizStep3Title,
    TextQuizStep3Title2
} from '../../config/Text'

class QuizStep3 extends React.Component
{
    constructor(props)
    {
        super(props)
        let question3 = sessionStorage.getItem('question_3');
        if ( question3 == null )
        {
            question3 = '';
        }
        let _nextDisable = this.questionValueValidate()
        
        this.state = 
        {
            question3 : question3,
            nextDisable : _nextDisable
        }
        this.handleChange = this.handleChange.bind(this)
    }

    questionValueValidate()
    {
        return sessionStorage.getItem('question_3') === null ||
        sessionStorage.getItem('question_3') === '' ||
        parseInt(sessionStorage.getItem('question_3')) < 1800 ||
        parseInt(sessionStorage.getItem('question_3')) > 2030
    }

    handleChange(event)
    {        
        this.setState({ 
            question3: event.target.value,
            nextDisable : this.questionValueValidate()
        })

        sessionStorage.setItem('question_3', event.target.value)

    }

    render()
    {
        if ( this.props.step !== '3' )
        {
            return null;
        }

        return (
            <>
               <section className="page page-quiz" data-step={this.props.step}>
                    <span className="progress"></span> 
                   <div className="container">
                       <div className="row">
                            <div className="col-md-7">

                                <h2>
                                    {TextQuizStep3Title} <br/>
                                    {TextQuizStep3Title2}
                                </h2>

                                <input 
                                    type="number"
                                    min="1"
                                    max="20"
                                    name="question_3"
                                    value={this.state.question3}
                                    onChange={this.handleChange}
                                    onKeyUp={this.handleChange}
                                    />

                                <QuizNav 
                                    step={this.props.step} 
                                    nextDisable={this.state.nextDisable} 
                                    prevClick={this.props.prevClick} 
                                    nextClick={this.props.nextClick} />
                            </div>
                            <div className="col-md-5 illustration illustration-3">
                                <img src={ilustration} alt="" />
                            </div>

                        </div>
                   </div>
               </section>
            </>
        )
    }
}

export default QuizStep3