import React from 'react'
import Newsletter from '../../components/Newsletter'
import InterestingApartments from '../../components/InterestingApartments';
import Estimate from '../../components/Estimate'
import axios from 'axios'

// import mockupDataApartments from '../../mockup-data/interesting-apartments.json'
import EstimateFactors from '../../components/EstimateFactors'


import { 
    TextQuizStep6_Option_0,
    TextQuizStep6_Option_1,
    TextQuizStep6_Option_2,
    TextQuizStep6_Option_3
 } from "../../config/Text";

class Result extends React.Component
{

    constructor(props)
    {
        super(props)
        this.state = 
        {
            estimated_price : '',
            user_estimated_price : null,
            apartments : null
        }
    }

    componentDidMount()
    {
        
        if ( this.props.step !== 'result' )
        {
            return;
        }

      //  if ( sessionStorage.getItem('question_0') !== null )
      //  {
            let payload = 
            {
                "build_age": parseFloat(sessionStorage.getItem('question_3')),
                "building_floors_num": parseFloat(sessionStorage.getItem('question_4')),
                "sqm": parseFloat(sessionStorage.getItem('question_1')),
                "rooms_num": parseFloat(sessionStorage.getItem('question_2')),
                "floor_no": parseFloat(sessionStorage.getItem('question_5')),
                "ad_lat": parseFloat(sessionStorage.getItem('question_7_lat')),
                "ad_long": parseFloat(sessionStorage.getItem('question_7_lng')),
                "word_balkon_taras": sessionStorage.getItem('question_6').indexOf(TextQuizStep6_Option_0) !== -1 ? 1 : 0,
                "word_winda": sessionStorage.getItem('question_6').indexOf(TextQuizStep6_Option_1) !== -1 ? 1 : 0,
                "word_postoj": sessionStorage.getItem('question_6').indexOf(TextQuizStep6_Option_2) !== -1 ? 1 : 0,
                "word_piwnica_komorka": sessionStorage.getItem('question_6').indexOf(TextQuizStep6_Option_3) !== -1 ? 1 : 0
            }

            let api = new URL(process.env.REACT_APP_MIESZKAY_API_URL)
            api.pathname = 'total_result'
            let self = this
            axios({
                url : api,
                method : 'POST',
                data : payload
            })
            .then( response => {
               
                let userEstimate = sessionStorage.getItem('question_8_masked')
                //self.quizReset()
                sessionStorage.setItem('estimated_price', response.data.estimated_price)
                sessionStorage.setItem('user_estimated_price', userEstimate)
                sessionStorage.setItem('result_build_age',payload.build_age)
                sessionStorage.setItem('result_building_floors_num',payload.building_floors_num)
                sessionStorage.setItem('result_sqm',payload.sqm)
                sessionStorage.setItem('result_rooms_num',payload.rooms_num)
                sessionStorage.setItem('result_floor_no',payload.floor_no)

                let apartments;
                let keys = Object.keys(response.data)
                if( keys.length > 3 )
                {
                    apartments = [
                        response.data[keys[0]],
                        response.data[keys[1]],
                        response.data[keys[2]]
                    ];
                }

                self.setState({
                    estimated_price : response.data.estimated_price,
                    apartments : apartments,
                    user_estimated_price : userEstimate
                })

            })
        // }
        // else 
        // {
        //     this.setState({
        //         estimated_price : sessionStorage.getItem('estimated_price'),
        //         user_estimated_price : sessionStorage.getItem('user_estimated_price')
        //     })
        // }

        /*
        let payload = {
            "build_age": sessionStorage.getItem('result_build_age'),
            "building_floors_num": sessionStorage.getItem('result_building_floors_num'),
            "sqm": sessionStorage.getItem('result_sqm'),
            "rooms_num": sessionStorage.getItem('result_rooms_num'),
            "floor_no": sessionStorage.getItem('result_floor_no'),
            "distance_from_center": 6.137233007596822
        }
        let api = new URL(process.env.REACT_APP_MIESZKAY_API_URL)
        api.pathname = 'recommend_apartment'
        let self = this
        axios({
            url : api,
            method : 'POST',
            data : payload
        })
        .then( response => {

            let keys = Object.keys(response.data)
            if( keys.length === 3 )
            {
                self.setState({ apartments : [
                    response.data[keys[0]],
                    response.data[keys[1]],
                    response.data[keys[2]]
                ]})
            }

        })
        */



    }

    quizReset()
    {
        sessionStorage.clear()
    }

    render()
    {
        if ( this.props.step !== 'result' )
        {
            return null;
        }
        return (
            
            <>
                <Estimate estimative={this.state.estimated_price} userEstimative={this.state.user_estimated_price} />
                <EstimateFactors />
                <InterestingApartments
                    apartments={this.state.apartments}
                />
                <Newsletter />
            </>
        )
    }
}

export default Result