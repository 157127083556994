import React from 'react'
import {Helmet} from "react-helmet";

import QuizStep0 from './quiz/QuizStep0'
import QuizStep1 from './quiz/QuizStep1'
import QuizStep2 from './quiz/QuizStep2'
import QuizStep3 from './quiz/QuizStep3'
import QuizStep4 from './quiz/QuizStep4'
import QuizStep5 from './quiz/QuizStep5'
import QuizStep6 from './quiz/QuizStep6'
import QuizStep7 from './quiz/QuizStep7'
import QuizStep8 from './quiz/QuizStep8'
import Result from './quiz/Result'

import {
    TextPageQuizTitle,
    TextPageQuizDescription,
} from '../config/Text'

class Quiz extends React.Component
{
    constructor(props)
    {
        super(props)
        this.prevClick = this.prevClick.bind(this)
        this.nextClick = this.nextClick.bind(this)
        this.state = 
        {
            progress : (((this.props.step+1)*9)/100)
        }
    }


    prevClick(event)
    {
        event.preventDefault()
        var prevIndex = parseInt(this.props.step)-1
        if ( prevIndex <= 0 )
        {
            window.location.href = '/quiz'
            return; 
        }
        window.location.href = `/quiz/${prevIndex}`
    }

    nextClick(event)
    {
        event.preventDefault()
        var nextIndex = parseInt(this.props.step)+1
        if( nextIndex <= 8 )
        {
            window.location.href = `/quiz/${nextIndex}`
        }
        else 
        {
            window.location.href = '/quiz/result'
        }
        
    }

    render()
    {
        return (
            <>
                <Helmet>
                    <title>{TextPageQuizTitle}</title>
                    <meta name="description" content={TextPageQuizDescription} />
                </Helmet>
                <QuizStep0 
                    step={this.props.step} 
                    prevClick={this.prevClick} 
                    nextClick={this.nextClick} />
                <QuizStep1 
                    step={this.props.step} 
                    prevClick={this.prevClick} 
                    nextClick={this.nextClick} />
                <QuizStep2
                    step={this.props.step} 
                    prevClick={this.prevClick} 
                    nextClick={this.nextClick} />
                <QuizStep3
                    step={this.props.step} 
                    prevClick={this.prevClick} 
                    nextClick={this.nextClick} />
                <QuizStep4
                    step={this.props.step} 
                    prevClick={this.prevClick} 
                    nextClick={this.nextClick} />
                <QuizStep5
                    step={this.props.step} 
                    prevClick={this.prevClick} 
                    nextClick={this.nextClick} />
                <QuizStep6
                    step={this.props.step} 
                    prevClick={this.prevClick} 
                    nextClick={this.nextClick} />
                <QuizStep7
                    step={this.props.step} 
                    prevClick={this.prevClick} 
                    nextClick={this.nextClick} />
                <QuizStep8
                    step={this.props.step} 
                    prevClick={this.prevClick} 
                    nextClick={this.nextClick} />
                <Result step={this.props.step}  />
            </>
        )
    }
}



export default Quiz;