import React from 'react'

import QuizNav from '../../components/QuizNav'

import ilustration from '../../img/quiz/quiz-step-1.png'

import {
    TextQuizStep1Title
} from '../../config/Text'

class QuizStep0 extends React.Component
{
    constructor(props)
    {
        super(props)
        let question1 = sessionStorage.getItem('question_1');
        if ( question1 == null )
        {
            question1 = '';
        }
        let _nextDisable = this.questionValueValidate()
        
        this.state = 
        {
            question1 : question1,
            nextDisable : _nextDisable
        }
        this.handleChange = this.handleChange.bind(this)
    }

    questionValueValidate()
    {
        return sessionStorage.getItem('question_1') === null ||
        sessionStorage.getItem('question_1') === '' ||
        parseInt(sessionStorage.getItem('question_1')) < 20 ||
        parseInt(sessionStorage.getItem('question_1')) > 300
    }

    handleChange(event)
    {        
        this.setState({ 
            question1: event.target.value,
            nextDisable : this.questionValueValidate()
        })

        sessionStorage.setItem('question_1', event.target.value)

    }

    render()
    {
        if ( this.props.step !== '1' )
        {
            return null;
        }

        return (
            <>
               <section className="page page-quiz" data-step={this.props.step}>
                    <span className="progress"></span> 
                    <div className="container">
                       <div className="row">
                            <div className="col-md-7">

                                <h2>
                                    {TextQuizStep1Title}
                                </h2>

                                <input 
                                    type="number"
                                    min="20"
                                    max="300"
                                    name="question_1"
                                    value={this.state.question1}
                                    onChange={this.handleChange}
                                    onKeyUp={this.handleChange}
                                    />

                                <QuizNav 
                                    step={this.props.step} 
                                    nextDisable={this.state.nextDisable} 
                                    prevClick={this.props.prevClick} 
                                    nextClick={this.props.nextClick} />
                            </div>
                            <div className="col-md-5 illustration illustration-1">
                                <img src={ilustration} alt="" />
                            </div>

                        </div>
                   </div>
               </section>
            </>
        )
    }
}

export default QuizStep0