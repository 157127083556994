import React from 'react'
import Swal from 'sweetalert2'
import {Helmet} from "react-helmet";

import { 
    TextContactMailInvalidText,
    TextContactConfirmButtonText,
    TextContactMailLabel,
    TextContactSubjectLabel,
    TextContactNameLabel,
    TextCommonButtonSend,
    TextContactNameInvalidText,
    TextContactSubjectInvalidText,
    TextContactMessageInvalidText,
    TextContactSuccessMessage,
    TextContactMessagePlaceholder,
    TextContactErrorMessage,
    TextPageContactTitle,
    TextPageContactDescription
 } from "../config/Text";
import axios from 'axios';

function Contact()
{
    return (
        <>
        <ContactPage />
        </>
    )
} 

function ContactPage()
{
    return (
        <section className="page page-contact">
            <div className="container">
                <h1>Kontakt</h1>
                <div className="row">
                    <div className="col-md-7">
                        <ContactForm />
                    </div>
                </div>
            </div>
        </section>
    )
}

class ContactForm extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = 
        {
            mail : '',
            name : '',
            subject : '',
            message : '',
            btnSendDisabled : false
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.formContactSubmit = this.formContactSubmit.bind(this)
    }

    handleInputChange(event)
    {

        const target = event.target
        const name = target.name
        const value = target.value

        this.setState({
            [name] : value
        })
    }

    formContactSubmit(event)
    {
        event.preventDefault()
        if ( !this.validate() )
        {
            return
        }

        let formData = new FormData(event.target)

        axios({
            method : 'POST',
            url : process.env.REACT_APP_FORMSPREE_ACTION,
            data : formData,
            headers : {
                'Accept' : 'application/json',
                'Content-Type' : 'multipart/form-data'
            }
        })
        .then( response => { 

            console.log(response)
            console.log(response.ok)

            if( response.status !== 200 )
            {
                //// error
                Swal.fire({
                    text: TextContactErrorMessage,
                    icon: 'error',
                    confirmButtonText: TextContactConfirmButtonText
                });
            }
            else 
            {
                
                //// sucesss
                Swal.fire({
                    text: TextContactSuccessMessage,
                    icon: 'success',
                    confirmButtonText: TextContactConfirmButtonText
                }).then(() => {
                    window.location.reload()
                });
            }
            

            this.setState({ btnSendDisabled : true })

        })
        .catch( response => {
            
            Swal.fire({
                text: TextContactErrorMessage,
                icon: 'error',
                confirmButtonText: TextContactConfirmButtonText
            });
        })



    }

    validate()
    {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if ( !re.test(this.state.mail) )
        {
            Swal.fire({
                text: TextContactMailInvalidText,
                icon: 'warning',
                confirmButtonText: TextContactConfirmButtonText
            })
            return false
        }
        if ( this.state.name.trim() === '' )
        {
            Swal.fire({
                text: TextContactNameInvalidText,
                icon: 'warning',
                confirmButtonText: TextContactConfirmButtonText
            })
            return false
        }
        if ( this.state.subject.trim() === '' )
        {
            Swal.fire({
                text: TextContactSubjectInvalidText,
                icon: 'warning',
                confirmButtonText: TextContactConfirmButtonText
            })
            return false
        }
        if ( this.state.message.trim() === '' )
        {
            Swal.fire({
                text: TextContactMessageInvalidText,
                icon: 'warning',
                confirmButtonText: TextContactConfirmButtonText
            })
            return false
        }
        return true
    }

    render()
    {
        return (
            <>
            <Helmet>
                <title>{TextPageContactTitle}</title>
                <meta name="description" content={TextPageContactDescription} />
            </Helmet>
            <form method="post" onSubmit={this.formContactSubmit}>
                <p>
                    <label htmlFor="mail">
                        {TextContactMailLabel}
                    </label>
                    <input 
                        name="mail" 
                        id="mail" 
                        type="mail" 
                        value={this.mail} 
                        onChange={this.handleInputChange} />
                </p>
                <p>
                    <label for="name">
                        {TextContactNameLabel}
                    </label>
                    <input 
                        name="name" 
                        id="name" 
                        type="text" 
                        value={this.name} 
                        onChange={this.handleInputChange} />
                </p>
                <p>
                    <label for="subject">
                        {TextContactSubjectLabel}
                    </label>
                    <input 
                        name="subject" 
                        id="subject" 
                        type="text" 
                        value={this.subject} 
                        onChange={this.handleInputChange} />
                </p>
                <p>
                    <label for="message"></label>

                    <textarea
                        name="message"
                        maxLength="2000"
                        value={this.message}
                        onChange={this.handleInputChange}
                        placeholder={TextContactMessagePlaceholder}
                    />
                </p>
                <p>
                    <label for="message"></label>
                    <span className="btn-send-align">
                        <button disabled={this.state.btnSendDisabled} className="btn btn-primary">
                            {TextCommonButtonSend}
                        </button>
                    </span>
                </p>
            </form>
            </>
        )
    }
}




export default Contact