import React from 'react'
import {Helmet} from "react-helmet";

import {
    Link
} from "react-router-dom"

import {
    TextPage404Title,
    TextPage404Subtitle,
    TextPage404BackTo,
    TextPage404BackToHomeTitle,
    TextPageNotFoundTitle,
    TextPageNotFoundDescription
} from './../config/Text'

function NotFound()
{
    return (
        <>
        <Helmet>
            <title>{TextPageNotFoundTitle}</title>
            <meta name="description" content={TextPageNotFoundDescription} />
        </Helmet>
        <section className="page page-not-found">
            <div className="container">
                <div className="row">
                    <div className="col-xl-1"></div>
                    <div className="col-xl-11">
                        <h1>
                            404
                        </h1>
                        <h3>
                            {TextPage404Title}
                        </h3>
                        <p>
                            {TextPage404Subtitle}
                        </p>
                        <p>
                            {TextPage404BackTo}
                            <Link to="/">
                                {TextPage404BackToHomeTitle}
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default NotFound;