import React from 'react'

import QuizNav from '../../components/QuizNav'

import ilustration from '../../img/quiz/quiz-step-2.png'

import {
    TextQuizStep2Title,
    TextQuizStep2Title2
} from '../../config/Text'

class QuizStep2 extends React.Component
{
    constructor(props)
    {
        super(props)
        let question2 = sessionStorage.getItem('question_2');
        if ( question2 == null )
        {
            question2 = '';
        }
        let _nextDisable = this.questionValueValidate()
        
        this.state = 
        {
            question2 : question2,
            nextDisable : _nextDisable
        }
        this.handleChange = this.handleChange.bind(this)
    }

    questionValueValidate()
    {
        return sessionStorage.getItem('question_2') === null ||
        sessionStorage.getItem('question_2') === '' ||
        parseInt(sessionStorage.getItem('question_2')) < 1 ||
        parseInt(sessionStorage.getItem('question_2')) > 20
    }

    handleChange(event)
    {        
        this.setState({ 
            question2: event.target.value,
            nextDisable : this.questionValueValidate()
        })

        sessionStorage.setItem('question_2', event.target.value)

    }

    render()
    {
        if ( this.props.step !== '2' )
        {
            return null;
        }

        return (
            <>
               <section className="page page-quiz" data-step={this.props.step}>
                    <span className="progress"></span>
                    <div className="container">
                       <div className="row">
                            <div className="col-md-7">

                                <h2>
                                    {TextQuizStep2Title} <br />
                                    {TextQuizStep2Title2}
                                </h2>

                                <input 
                                    type="number"
                                    min="1"
                                    max="20"
                                    name="question_2"
                                    value={this.state.question2}
                                    onChange={this.handleChange}
                                    onKeyUp={this.handleChange}
                                    />

                                <QuizNav 
                                    step={this.props.step} 
                                    nextDisable={this.state.nextDisable} 
                                    prevClick={this.props.prevClick} 
                                    nextClick={this.props.nextClick} />
                            </div>
                            <div className="col-md-5 illustration illustration-2">
                                <img src={ilustration} alt="" />
                            </div>

                        </div>
                   </div>
               </section>
            </>
        )
    }
}

export default QuizStep2