import React from 'react'
import {Link} from 'react-router-dom'
import {TextCallToCalculate,TextCallToCalculateLink} from './../config/Text'

function CallToCalculate()
{
    return (
        <section className="section-call-to-calculate">
            <div className="container">
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10">
                        <p>
                            {TextCallToCalculate}
                            <br/>
                            <Link to="/quiz">
                                {TextCallToCalculateLink}
                            </Link>
                        </p>
                    </div>
                    <div className="col-md-1"></div>
                </div>
            </div>
        </section>
    )
}

export default CallToCalculate