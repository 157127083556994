import React from 'react'

import {
    TextEstimateFactorsPositiveTitle,
    TextEstimateFactorsPositiveText,
    TextEstimateFactorsNegativeTitle,
    TextEstimateFactorsNegativeText
} from './../config/Text'

class EstimateFactors extends React.Component
{
    render()
    {

        function TextEstimateFatorsList(props)
        {
            return props.items.map( item => {
                return (<p>{item}</p>)
            })
        }

        return (
            <section className="section-estimate-factors">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h3>
                                {TextEstimateFactorsPositiveTitle}
                            </h3>
                            <TextEstimateFatorsList items={TextEstimateFactorsPositiveText} />
                            
                            <h3 className="mt-5">
                                {TextEstimateFactorsNegativeTitle}
                            </h3>
                            <TextEstimateFatorsList items={TextEstimateFactorsNegativeText} />
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>
            </section>
        )
    }
}

export default EstimateFactors