import React from 'react'

// banner 1
import banner1 from '../img/banner-slide/buildings.jpg'
import banner1Webp from '../img/banner-slide/buildings.webp'
/// banner 2
import banner2 from '../img/banner-slide/dogs.jpg'
import banner2Webp from '../img/banner-slide/dogs.webp'
// banner 3
import banner3 from '../img/banner-slide/family.jpg'
import banner3Webp from '../img/banner-slide/family.webp'
// banner 4
import banner4 from '../img/banner-slide/interior.jpg'
import banner4Webp from '../img/banner-slide/interior.webp'

class BannerSlide extends React.Component
{
    componentDidMount()
    {
        setInterval(() => {
            if ( document.querySelector('.banner-images') == null )
            {
                return;
            }
            let activeEl = document.querySelector('.banner-images .active')
            if( activeEl.nextSibling != null )
            {
                activeEl.classList.remove('active')
                activeEl.nextSibling.classList.add('active')
            }
            else 
            {
                activeEl.classList.remove('active')
                document.querySelector('.banner-images picture:first-child').classList.add('active')
            }
        },8000);
    }
    render() {



        return (
        <div className="banner-slide">
            <div className="banner-images">
                <picture className="active">
                    <source type="image/webp" srcset={banner1Webp} />
                    <img src={banner1} type="image/jpg" alt="" />
                </picture>
                <picture>
                    <source type="image/webp" srcset={banner2Webp} />
                    <img src={banner2} type="image/jpg" alt="" />
                </picture>
                <picture>
                    <source type="image/webp" srcset={banner3Webp} />
                    <img src={banner3}  type="image/jpg" alt="" />
                </picture>
                <picture>
                    <source type="image/webp" srcset={banner4Webp} />
                    <img src={banner4} type="image/jpg" alt="" />
                </picture>
            </div>
        </div>
        )
    }
}

export default BannerSlide