import React from 'react'
import Swal from 'sweetalert2'

import {
    TextNewsLetterSign,
    TextCommonButtonSend,
    TextNewsLetterMailInvalidText,
    TextNewsLetterMailConfirmButtonText,
    TextNewsLetterMailSuccessMessage,
    TextNewsLetterMailErrorMessage
} from './../config/Text'

import axios from 'axios'

class Newsletter extends React.Component
{

    constructor(props)
    {
        super(props)
        
        this.state = 
        {
            mail : '',
            sendButtonDisable : false
        }
        this.handleChange = this.handleChange.bind(this)
        this.newsLetterFormSubmit = this.newsLetterFormSubmit.bind(this)
        
    } 

    handleChange(event)
    {
        this.setState({ mail: event.target.value })
    }

    newsLetterFormSubmit(event)
    {
        event.preventDefault()
        if ( !this.validate() )
        {
            Swal.fire({
                text: TextNewsLetterMailInvalidText,
                icon: 'warning',
                confirmButtonText: TextNewsLetterMailConfirmButtonText
            })
            return
        }

        this.setState({ sendButtonDisable : true })

        let self = this

        let formData = new FormData()
        formData.set('mail', this.state.mail)

        axios({
            method : 'POST',
            url : process.env.REACT_APP_FORMCARRY_ACTION,
            data : formData,
            headers : {
                'Accept' : 'application/json',
                'Content-Type' : 'multipart/form-data'
            }
        })
        .then( response => {

            if( response.status !== 200 )
            {
                /// error
                Swal.fire({
                    text: TextNewsLetterMailErrorMessage,
                    icon: 'error',
                    confirmButtonText: TextNewsLetterMailConfirmButtonText
                });
            }
            else 
            {
                /// success
                Swal.fire({
                    text: TextNewsLetterMailSuccessMessage,
                    icon: 'success',
                    confirmButtonText: TextNewsLetterMailConfirmButtonText
                });
            }

            self.setState({ mail : '' })
            self.setState({ sendButtonDisable : false })

            
        })
        .catch( response => {
            
            Swal.fire({
                text: TextNewsLetterMailErrorMessage,
                icon: 'error',
                confirmButtonText: TextNewsLetterMailConfirmButtonText
            });
        })


    }
 
    validate()
    {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(this.state.mail)
    }

    render ()
    {
        return (
            <section className="section-newsletter">
                <div className="container">
                    <form method="post" onSubmit={this.newsLetterFormSubmit} action="https://formspree.io/sadsasdasdasdasd" className="row">
                        <div className="col-lg-3 col-md-3 col-xs-1"></div>
                        <div className="col-lg-6 col-md-6 col-xs-6">
                            <h4>
                                {TextNewsLetterSign}
                            </h4>
                            <input type="text" name="mail" value={this.state.mail} onChange={this.handleChange} maxLength="100" />
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-3 col-with-button">
                            <button disabled={this.state.sendButtonDisable} className="btn btn-primary">
                                {TextCommonButtonSend}
                            </button> 
                        </div>
                        <div className="col-lg-1 col-md-1 col-xs-1"></div>
                    </form>
                </div>
            </section> 
        )
    }
}

export default Newsletter