import React from 'react'

//// SEO
export const TextPageContactTitle = "Mieszkay - Kontakt"
export const TextPageContactDescription = ""
export const TextPageHomeTitle = "Mieszkay"
export const TextPageHomeDescription = ""
export const TextPageQuizTitle = "Mieszkay - Wycena mieszkania"
export const TextPageQuizDescription = ""
export const TextPageNotFoundTitle = "Mieszkay"
export const TextPageNotFoundDescription = ""
export const TextPagePrivacyPolicyTitle = "Mieszkay"
export const TextPagePrivacyPolicyDescription = ""
export const TextPageRegulationsTitle = "Mieszkay"
export const TextRegulationsDescription = ""

//// Common
export const TextCommonProjectName = "Mieszkay"
export const TextCommonButtonSend = 'Wyślij'
export const TextCommonButtonPrev = 'Wróć'
export const TextCommonButtonNext = 'Dalej'

/// Header
export const TextHeaderNavItemHome = "Strona Główna"
export const TextHeaderNavItemQuiz = "Wyceń mieszkanie"
export const TextHeaderNavItemContact = "Kontakt"

/// Footer
export const TextFooterNavItemPrivacyPolicy = "Polityka prywatności"
export const TextFooterNavItemRegulations = "Regulamin"
export const TextFooterPhone = "22 XXX YY ZZ"
export const TextFooterMail = "kontakt@mieszkay.com"
export const TextFooterDevelopedBy = "Desenvolvido por"
export const TextFooterDevelopedBySite = "https://uniq.vc"
export const TextFooterDevelopedByName = "Uniq"

//// QUIZ
//// STEP 0
export const TextQuizStep0Tip = "Aby ocenić wartość mieszkania wypełnij następujący formularz składający sie z kilku pytań"
export const TextQuizStep0Title = "Interesuje cię sprzedaż czy kupno mieszkania?"
export const TextQuizStep0_Option_0 = "Kupno"
export const TextQuizStep0_Option_1 = "Sprzedaż"
///// STEP 1
export const TextQuizStep1Title = "Liczba metrów kwadratowych mieszkania ? Podaj liczbę między 20 a 300."

///// STEP 2
export const TextQuizStep2Title = "Liczba pokoi w mieszkaniu ?"
export const TextQuizStep2Title2 = "Podaj liczbę między 1 a 20."

///// STEP 3
export const TextQuizStep3Title = "Rok budowy ?"
export const TextQuizStep3Title2 = "Podaj liczbę między 1800 a 2030."

///// STEP 4
export const TextQuizStep4Title = "Ilość pięter w budynku ? Podaj liczbę między 0 a 50."

///// STEP 5
export const TextQuizStep5Title = "Numer piętra na ktorym mieszkanie się znajduje ? Podaj liczbę między 0 a 50."

///// STEP 6
export const TextQuizStep6Title = "Które z poniższych opcji posiada Twoje mieszkanie?"
export const TextQuizStep6_Option_0 = "Balkon lub taras"
export const TextQuizStep6_Option_1 = "Windę"
export const TextQuizStep6_Option_2 = "Miejsce parkingowe"
export const TextQuizStep6_Option_3 = "Piwnice"
export const TextQuizStep6_Option_4 = "żadna z alternatyw"

///// STEP 7
export const TextQuizStep7Title = "Na jakiej ulicy znajduję się mieszkanie ?"
export const TextQuizStep7FillAddress = "Wypełnij adres i zweryfikuj na mapie lokalizację"
export const TextQuizStep7AddressNotFound = "Nie znaleziono adresu. Proszę spróbować podać okolicę."
export const TextQuizStep7SearchButtonTitle = "Wyszukaj"

///// STEP 8
export const TextQuizStep8Title = "Dla naszej własnej ciekawości - na ile sami szacujecie wartość takiego mieszkania ?"
export const TextQuizStep8Title2 = "(Dobrowolne)"

//// RESULT
export const TextQuizResultTitle = "Szacujemy wartość mieszkania o podanych parametrach na:"
export const TextQuizResultYourEstimateWas = "Podczas gdy Twoj szacunek to: "

///// END QUIZ

//// PAGE 404
export const TextPage404Title = "Nie odnaleziono strony :("
export const TextPage404Subtitle = "Przepraszamy, ale strona nie istnieje :("
export const TextPage404BackTo = "Wróc"
export const TextPage404BackToHomeTitle = "Strona główna"

/// BANNER
export const TextBannerAbout = "Mieszkay jest platformą zarówno dla kupujących, sprzedających jak i osób szukujących lub zajmujących się wynajmowaniem mieszkań."
/// USER ECONOMY
export const TextBannerEconomy = "Użytkownicy Mieszkay oszczędzili"
export const TextBannerEconomy2 = "dzięki analizie cenowej"
export const TextBannerLinkToQuiz = "Wyceń mieszkanie"

/// CALL TO CALCULATE
export const TextCallToCalculate = "Mieszkay jest pierwszą w Polsce platforma pozwalającą na szybkie i precyzyjne oszacowanie cen objektów nieruchomościowych. Stworzone przez nas rozwiązanie, bazuje na zaawansowanych algorytmach i dokładnej analizie stanu rynku nieruchomości. Wypełnij formularz poniżej aby za darmo poznać "
export const TextCallToCalculateLink = "wartość nieruchomości"

/// USER ECONOMY
export const TextUserEconomyBeforeAmount = "Użytkownicy Mieszkay oszczędzili"
export const TextUserEconomyAfterAmount = "dzięki analizie cenowej"

//// INTERESTING APARTMENTS
export const TextInterestingApartmentsRooms = "pokoi"
export const TextInterestingApartmentsFloor = "pięter"
export const TextInterestingApartmentsYear = "Rok"
export const TextInterestingApartments = "Ciekawe mieszkania"

/// NEWSLETTER
export const TextNewsLetterSign = "Zapisz się do naszego newslettera aby otrzymać aktualności."
export const TextNewsLetterMailInvalidText = "Podaj poprawny email."
export const TextNewsLetterMailConfirmButtonText = "OK"
export const TextNewsLetterMailSuccessMessage = "Dziękujemy za wpisanie się do naszego newslettera."
export const TextNewsLetterMailErrorMessage = "Nastąpił błąd i nie udało się dodać Twojego emailu."

/// RESULT
export const TextEstimateFactorsPositiveTitle = "Czynniki potencjalnie podnoszące wartość mieszkania:"
export const TextEstimateFactorsPositiveText = [
                                                    "- Stosunkowo niedawno przeprowadzony remont. - Dobra dostępność środków transportu miejskiego. - Lokalizacja niedaleko terenów zielonych.",
                                                ]
export const TextEstimateFactorsNegativeTitle = "Czynniki potencjalnie obniżające wartość mieszkania"
export const TextEstimateFactorsNegativeText = [
                                                    "- Lokalizacja przy głośnej ulicy. - Brak dostepu słońca w mieszkaniu."
                                                ]

//// PAGE CONTACT
export const TextContactMailInvalidText = "Podaj prawidłowy email"
export const TextContactNameInvalidText = "Podaj imię"
export const TextContactSubjectInvalidText = "Podaj temat wiadomości"
export const TextContactMessageInvalidText = "Wiadomość nie może być pusta"
export const TextContactConfirmButtonText = "OK"
export const TextContactMailLabel = "Email"
export const TextContactNameLabel = "Imię"
export const TextContactSubjectLabel = "Temat"
export const TextContactSuccessMessage = "Wiadomość została wysłana."
export const TextContactErrorMessage = "Nastąpił błąd wysłaniu wiadomości"
export const TextContactMessagePlaceholder = "Napisz wiadomość"



//// PAGE PRIVACY POLICY
export function TextPrivacyPolicy()
{
    return (
        <>
        <h1>Políticas de Privacidade</h1>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras molestie cursus risus at commodo. Ut ultricies mi vitae erat volutpat, sit amet ornare lacus ultricies. Nunc cursus mauris sit amet dolor molestie, vitae ornare sem facilisis. Vestibulum at vestibulum orci. Curabitur ultrices interdum scelerisque. Maecenas et odio at sem pulvinar semper vitae nec risus. Vestibulum viverra auctor purus, id congue libero fermentum nec. Aliquam erat volutpat. Phasellus quis enim erat. Phasellus semper erat justo, sed lacinia libero mollis sit amet. Aliquam erat volutpat. Pellentesque interdum et tellus ac elementum. Maecenas quis eleifend lacus, eu rhoncus erat. Aenean commodo sem id nibh sodales, eget elementum magna lacinia. Mauris risus felis, egestas id egestas vitae, imperdiet et turpis. Fusce nunc velit, porta a metus sit amet, suscipit vulputate nisl.
        </p>
        <p>
            Aliquam accumsan lectus lorem, vitae tincidunt nisl pulvinar id. Ut ut gravida enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec finibus fringilla ligula, a tincidunt risus egestas a. Aliquam eget neque et sem rhoncus bibendum ac id metus. Sed eget ullamcorper ipsum. Morbi vel velit mauris. Integer varius, libero sit amet maximus lobortis, leo elit porta lacus, non elementum nisi tellus quis mauris. Duis mollis lorem a viverra lacinia. Morbi ut tortor mi. Ut at finibus est, a euismod tortor. Cras nec interdum erat. Pellentesque eget arcu a risus interdum aliquet quis ut massa. Donec eget dictum felis.
        </p>
        <p>
            Aenean enim tellus, laoreet non nisi eu, fermentum pretium nibh. Sed hendrerit dolor non diam fermentum auctor. Nulla orci felis, porta ac ligula eu, cursus cursus lectus. In ac accumsan justo. Sed dictum sem eu sollicitudin gravida. Integer eleifend ante lectus, ut blandit felis posuere nec. Morbi tempus sed felis in vestibulum. Etiam imperdiet quam vel ex pharetra varius. Sed laoreet vehicula enim, et volutpat metus bibendum in.
        </p>
        </>
    )
}

//// PAGE REGULATIONS
export function TextRegulations()
{
    return (
        <>
        <h1>Regulamento</h1>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras molestie cursus risus at commodo. Ut ultricies mi vitae erat volutpat, sit amet ornare lacus ultricies. Nunc cursus mauris sit amet dolor molestie, vitae ornare sem facilisis. Vestibulum at vestibulum orci. Curabitur ultrices interdum scelerisque. Maecenas et odio at sem pulvinar semper vitae nec risus. Vestibulum viverra auctor purus, id congue libero fermentum nec. Aliquam erat volutpat. Phasellus quis enim erat. Phasellus semper erat justo, sed lacinia libero mollis sit amet. Aliquam erat volutpat. Pellentesque interdum et tellus ac elementum. Maecenas quis eleifend lacus, eu rhoncus erat. Aenean commodo sem id nibh sodales, eget elementum magna lacinia. Mauris risus felis, egestas id egestas vitae, imperdiet et turpis. Fusce nunc velit, porta a metus sit amet, suscipit vulputate nisl.
        </p>
        <p>
            Aliquam accumsan lectus lorem, vitae tincidunt nisl pulvinar id. Ut ut gravida enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec finibus fringilla ligula, a tincidunt risus egestas a. Aliquam eget neque et sem rhoncus bibendum ac id metus. Sed eget ullamcorper ipsum. Morbi vel velit mauris. Integer varius, libero sit amet maximus lobortis, leo elit porta lacus, non elementum nisi tellus quis mauris. Duis mollis lorem a viverra lacinia. Morbi ut tortor mi. Ut at finibus est, a euismod tortor. Cras nec interdum erat. Pellentesque eget arcu a risus interdum aliquet quis ut massa. Donec eget dictum felis.
        </p>
        <p>
            Aenean enim tellus, laoreet non nisi eu, fermentum pretium nibh. Sed hendrerit dolor non diam fermentum auctor. Nulla orci felis, porta ac ligula eu, cursus cursus lectus. In ac accumsan justo. Sed dictum sem eu sollicitudin gravida. Integer eleifend ante lectus, ut blandit felis posuere nec. Morbi tempus sed felis in vestibulum. Etiam imperdiet quam vel ex pharetra varius. Sed laoreet vehicula enim, et volutpat metus bibendum in.
        </p>
        </>
    )
}