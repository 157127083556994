import React from 'react'

import {
    TextQuizResultTitle,
    TextQuizResultYourEstimateWas
} from './../config/Text'

import NumberFormat from 'react-number-format';

class Estimate extends React.Component
{
    render()
    {

        function UserEstimate(props)
        {
            if( props.userEstimative === null )
            {
                return '';
            }
            else {
                return (<h3>
                            {TextQuizResultYourEstimateWas} {props.userEstimative}
                        </h3>)
            }
        }

        return (
            <section className="section-estimate">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-7">
                            <p>
                                <i className="fas fa-check"></i>
                                {TextQuizResultTitle}
                            </p>
                            <h2>
                                <NumberFormat 
                                    value={this.props.estimative}
                                    displayType={'text'} 
                                    thousandSeparator={' '}
                                    prefix={''} />
                                
                            </h2>
                            <UserEstimate userEstimative={this.props.userEstimative} />
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Estimate