import React from 'react'

import {
    TextInterestingApartments,
    TextInterestingApartmentsRooms,
    TextInterestingApartmentsFloor,
    TextInterestingApartmentsYear
} from '../config/Text'

class InterestingApartments extends React.Component
{


    render()
    {

        if( this.props.apartments == null )
        {
            return null
        }

        return (
            <section className="interesting-apartments">
                <div className="container">
                    <h2>
                        {TextInterestingApartments}
                    </h2>
                    <div className="row">
                        {this.props.apartments.map( (ap,i) => {
                            return <ApartmentCard
                                        url={ap.link}
                                        meters={ap.sqm}
                                        street={ap.address}
                                        number={ ap.address.replace(/[^0-9.]/g, "") }
                                        price_difference={ap.price}
                                        neighborhood={ap.district}
                                        rooms={ap.rooms_num}
                                        floor={ap.floor_no}
                                        year={ap.build_year}
                                        // images={''}
                                       images={ap.main_img.join(',')}
                                    />
                        })}  
                    </div>
                </div>
            </section>
        )
    }
}

class ApartmentCard extends React.Component
{

    constructor(props)
    {
        super(props)
        this.updateNavigator = this.updateNavigator.bind(this);
        this.btnPrevClick = this.btnPrevClick.bind(this);
        this.btnNextClick = this.btnNextClick.bind(this);
        this.btnNavToClick = this.btnNavToClick.bind(this);
        this.renderNavigation = this.renderNavigation.bind(this);
    }

    updateNavigator(images)
    {
        let activeIndex = 0;
        let count = images.querySelectorAll('img').length;
        for( let i = 0; i < count; i++ )
        {
            if( images.querySelectorAll('img')[i].classList.contains('active') )
            {
                activeIndex = i;
            }
        }
        images.querySelector('.navigation .active').classList.remove('active');
        images.querySelectorAll('.navigation i')[activeIndex].classList.add('active');
    }

    btnPrevClick(e)
    {
        e.preventDefault();
        var images = e.target.parentElement.parentElement;
        var activeEl = images.querySelector('img.active');
        if ( activeEl.previousSibling.tagName === 'IMG' )
        {
            activeEl.classList.remove('active')
            activeEl.previousSibling.classList.add('active')
        }
        else 
        {
            activeEl.classList.remove('active')
            images.querySelector('img:last-child').classList.add('active')
        }
        this.updateNavigator(images);
    }

    btnNextClick(e)
    {
        e.preventDefault();
        var images = e.target.parentElement.parentElement;
        var activeEl = images.querySelector('img.active');
        if ( activeEl.nextSibling != null )
        {
            activeEl.classList.remove('active')
            activeEl.nextSibling.classList.add('active')
        }
        else 
        {
            activeEl.classList.remove('active')
            images.querySelector('img').classList.add('active')
        }
        this.updateNavigator(images)
    }

    btnNavToClick(e)
    {
        e.preventDefault();
        var to = e.target.dataset.index
        var images = e.target.parentElement.parentElement
        images.querySelector('img.active').classList.remove('active')
        images.querySelectorAll('img')[to].classList.add('active')
        this.updateNavigator(images)
    }

    renderImage(url,i)
    {
        return (
            <img className={i===0?'active':''} src={url} alt="" />
        )
    }

    renderNavigation(el,i)
    {
        return (<i onClick={this.btnNavToClick} data-index={i} className={i===0?'active':''}></i>)
    }

    render ()
    {

        let images;
        if( this.props.images === '' )
        {
            images = [];
        }
        else 
        {
            images = this.props.images.split(',');
        }
        return (
            <div key={Math.floor(Math.random()*1000)} className="col-md-4">
                <a key="1" className={'images images-count-' + images.length} href={this.props.url} rel="noopener noreferrer" target="_blank">
                    <span onClick={this.btnPrevClick} className="nextPrev btnPrev">
                        <i className="fas fa-chevron-left"></i> 
                    </span>
                    <span onClick={this.btnNextClick} className="nextPrev btnNext">
                        <i className="fas fa-chevron-right"></i> 
                    </span>
                    <span className="navigation">
                        {images.map(this.renderNavigation)}
                    </span>
                    <span className="mask"></span>
                    {images.map(this.renderImage)}
                    <i className="fa fa-image"></i>
                </a>
                <a key="2" className="info-line-1" title={this.props.street} href={this.props.url} rel="noopener noreferrer" target="_blank">
                    {this.props.meters}m<sup>2</sup>
                    <span className="street">
                        {this.props.street}
                    </span>
                    {this.props.number}
                    <span className="price">
                        {this.props.price}
                    </span>
                </a>
                <a key="3" className="info-line-2" href={this.props.url} rel="noopener noreferrer" target="_blank">
                    <span className="neighborhood">
                        {this.props.neighborhood}
                    </span>
                    <span className="rooms">
                        <span>{this.props.rooms} {TextInterestingApartmentsRooms}</span>
                        <span>{this.props.floor} {TextInterestingApartmentsFloor}</span>
                        <span>{this.props.year} {TextInterestingApartmentsYear}</span>
                    </span>
                </a>
            </div>
        )
    }
}

export default InterestingApartments