import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"

import './App.scss'

import Header from './components/Header';
import Footer from './components/Footer';

import PageHome from './pages/Home'
import PageContact from './pages/Contact'
import Quiz from './pages/Quiz'
import PageNotFound from './pages/NotFound'
import PagePrivacyPolicy from './pages/PrivacyPolicy'
import PageRegulations from './pages/Regulations'
import ScrollToTop from './components/ScrollToTop'

function App() {
  return (
    <>
    <Router>
      <Header />
      <ScrollToTop />
      <Switch>
        <Route exact path="/quiz"><Quiz step="0" /></Route>
        <Route exact path="/quiz/1"><Quiz step="1" /></Route>
        <Route exact path="/quiz/2"><Quiz step="2" /></Route>
        <Route exact path="/quiz/3"><Quiz step="3" /></Route>
        <Route exact path="/quiz/4"><Quiz step="4" /></Route>
        <Route exact path="/quiz/5"><Quiz step="5" /></Route>
        <Route exact path="/quiz/6"><Quiz step="6" /></Route>
        <Route exact path="/quiz/7"><Quiz step="7" /></Route>
        <Route exact path="/quiz/8"><Quiz step="8" /></Route>
        <Route exact path="/quiz/result"><Quiz step="result" /></Route>
        <Route path="/contact">
          <PageContact />
        </Route>
        <Route path="/privacy-policy">
          <PagePrivacyPolicy />
        </Route>
        <Route path="/regulations">
          <PageRegulations />
        </Route>
        <Route exact path="/">
          <PageHome />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
      <Footer />
    </Router>
    
    </>
  );
}

export default App;
