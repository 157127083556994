import React from 'react'

import { 
    TextCommonButtonPrev,
    TextCommonButtonNext
 } from "../config/Text";

class QuizNav extends React.Component
{

    constructor(props)
    {
        super(props)
        this.state = 
        {
            backButtonVisible : true
        }
    }

    componentDidMount()
    {
        if ( this.props.step === '0' )
        {
            this.setState({
                backButtonVisible : false
            })
        }
    }

    render()
    {
        return (
            <>
                <div className="quiz-nav">
                    <button 
                        type="button" 
                        onClick={this.props.prevClick}
                        className={
                                'btn btn-secondary ' + 
                                (this.state.backButtonVisible ? '' : 'd-none')}>
                        {TextCommonButtonPrev}
                    </button>
                    <button 
                        type="button" 
                        className="btn btn-primary" 
                        disabled={this.props.nextDisable}
                        onClick={this.props.nextClick} >
                        {TextCommonButtonNext}
                    </button>
                </div>
            </>
        )
    }
}

export default QuizNav