import React from 'react'

import CurrencyInput from 'react-currency-input'
import QuizNav from '../../components/QuizNav'

import ilustration from '../../img/quiz/quiz-step-8.png'

import {
    TextQuizStep8Title,
    TextQuizStep8Title2
} from '../../config/Text'



class QuizStep8 extends React.Component
{
    constructor(props)
    {
        super(props)
        let question8 = sessionStorage.getItem('question_8');
        if ( question8 === 'null' )
        {
            question8 = '';
        }
        
        this.state = 
        {
            question8 : question8,
        }
        this.handleChange = this.handleChange.bind(this)


    }
    handleChange(maskedvalue, value, floatvalue)
    {        
        this.setState({ 
            question8: value
        })
        sessionStorage.setItem('question_8', value)
        sessionStorage.setItem('question_8_masked', maskedvalue)
    }

    render()
    {
        if ( this.props.step !== '8' )
        {
            return null;
        }

        return (
            <>
               <section className="page page-quiz" data-step={this.props.step}>
                    <span className="progress"></span>
                    <div className="container">
                       <div className="row">
                            <div className="col-md-7">

                                <h2>
                                    {TextQuizStep8Title}<br/> 
                                    {TextQuizStep8Title2} 
                                </h2>

                                <CurrencyInput 
                                    placeholder="000 000"
                                    name="question_8"
                                    decimalSeparator=" " 
                                    thousandSeparator=" "
                                    precision="0"
                                    onChange={this.handleChange}
                                    value={this.state.question8}
                                    />

                                <QuizNav 
                                    step={this.props.step} 
                                    nextDisable={this.state.nextDisable} 
                                    prevClick={this.props.prevClick} 
                                    nextClick={this.props.nextClick} />
                            </div>
                            <div className="col-md-5 illustration illustration-5">
                                <img src={ilustration} alt="" />
                            </div>

                        </div>
                   </div>
               </section>
            </>
        )
    }
}

export default QuizStep8