import React from 'react'
/* global initMap */

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Swal from 'sweetalert2'
import axios from 'axios'

import QuizNav from '../../components/QuizNav'
import {TextNewsLetterMailConfirmButtonText} from '../../config/Text'

import {
    TextQuizStep7Title,
    TextQuizStep7FillAddress,
    TextQuizStep7AddressNotFound,
    TextQuizStep7SearchButtonTitle
} from '../../config/Text'


class QuizStep7 extends React.Component
{
    constructor(props)
    {
        super(props)        
        this.state = 
        {
            nextDisable : true,
            geometry : null
        }

        this.searchAddress = this.searchAddress.bind(this)

    }   

    searchAddress(event)
    {
        event.preventDefault()
        let address = document.querySelector('#react-google-places-autocomplete-input').value

        if ( address.trim() === '' )
        {
            Swal.fire({
                text: TextQuizStep7FillAddress,
                icon: 'error',
                confirmButtonText: TextNewsLetterMailConfirmButtonText
            });
            return;
        }

        /*
        let openCageAPI = new URL('https://api.opencagedata.com/geocode/v1/json')
        console.log(address);
        openCageAPI.searchParams.append('q', address )
       // openCageAPI.searchParams.append('language', 'pt')
        openCageAPI.searchParams.append('pretty', '1')
       // openCageAPI.searchParams.append('countrycode', 'pl')
        //openCageAPI.searchParams.append('limit', '1')
      //  openCageAPI.searchParams.append('limit', '1')
        openCageAPI.searchParams.append('key', process.env.REACT_APP_OPEN_CAGE_API_KEY)
        */

        let openCageAPI = 'https://api.opencagedata.com/geocode/v1/json?';
        openCageAPI += 'q=' + encodeURIComponent(address);
        openCageAPI += '&key=' + process.env.REACT_APP_OPEN_CAGE_API_KEY;
        openCageAPI += '&pretty=1';

        let self = this;
        axios.get(openCageAPI)
            .then(function (response) {
               
                if( !response.data || 
                    !response.data.results || 
                    response.data.results.length === 0 )
                {
                    Swal.fire({
                        text: TextQuizStep7AddressNotFound,
                        icon: 'error',
                        confirmButtonText: TextNewsLetterMailConfirmButtonText
                    });
                    self.setState({
                        nextDisable : true
                    })
                }
                else 
                {
                    let geometry = response.data.results[0].geometry
                    sessionStorage.setItem('question_7_lat', geometry.lat)
                    sessionStorage.setItem('question_7_lng', geometry.lng)
                    initMap(geometry,14,true);
                    self.setState({
                        nextDisable : false
                    })
                }
                
               
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
        
    }


    render()
    {
        if ( this.props.step !== '7' )
        {
            return null;
        }

        /// remove stored user estimative
        sessionStorage.removeItem('question_8')
        sessionStorage.removeItem('question_8_masked')


        return (
            <>
               <section className="page page-quiz map" data-step={this.props.step}>
                   <span className="progress"></span>
                   <div className="container">
                       <div className="row">
                            <div className="col-md-6">

                                <h2>
                                    {TextQuizStep7Title}
                                </h2>

                                <GooglePlacesAutocomplete
                                    key="GooglePlacesAutocomplete"
                                    placeholder=""
                                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                    initialValue={this.state.question7}
                                    autocompletionRequest={{
                                        componentRestrictions: {
                                            country: ['pl'] // Only Poland
                                        }
                                    }}
                                />


                                <div className="mt-3 mb-5 text-right">
                                    <button onClick={this.searchAddress}  className="btn btn-primary">
                                        {TextQuizStep7SearchButtonTitle}
                                    </button>
                                </div>

                                <div className="mt-2 empty"></div>

                                <QuizNav 
                                    step={this.props.step}  
                                    nextDisable={this.state.nextDisable} 
                                    prevClick={this.props.prevClick} 
                                    nextClick={this.props.nextClick} />
                            </div>
                            <div className="className col-md-1"></div>
                            <div className="col-md-5 map-border">
                                <div className="map-area">
                                    
                                    <div id="map"></div>
                                </div>
                            </div>

                        </div>
                   </div>
               </section>
            </>
        )
    }
}



export default QuizStep7