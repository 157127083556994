import React from 'react'

import QuizNav from '../../components/QuizNav'

import ilustration from '../../img/quiz/quiz-step-6.png'

import {
    TextQuizStep6Title,
    TextQuizStep6_Option_0,
    TextQuizStep6_Option_1,
    TextQuizStep6_Option_2,
    TextQuizStep6_Option_3,
    TextQuizStep6_Option_4
} from '../../config/Text'

class QuizStep6 extends React.Component
{
    constructor(props)
    {
        super(props)
        let question6 = sessionStorage.getItem('question_6');
        if ( question6 == null )
        {
            question6 = '';
        }
        let _nextDisable = this.questionValueValidate()
        
        this.state = 
        {
            question6 : question6,
            nextDisable : _nextDisable
        }
        this.handleChange = this.handleChange.bind(this)
    }

    questionValueValidate()
    {
        return document.querySelectorAll('[name=question_6]:checked').length === 0
    }

    handleChange(event)
    {        

        //// prevent click on none of alternative
        if( 
            event.target.value === TextQuizStep6_Option_4 && 
            document.querySelectorAll('[name=question_6]:checked').length > 1
        )
        {
            event.preventDefault()
            document.querySelector('#question_6_e').checked = false
            return false;
        }

        var instalations = []
        document.querySelectorAll('[name=question_6]:checked')
        .forEach( el => {
            instalations.push(el.value)
        })
        instalations = instalations.join('||')

        /// none of alternatives
        if( instalations !== '' && instalations.indexOf(`||${TextQuizStep6_Option_4}`) !== -1 )
        {
            document.querySelector('#question_6_e').checked = false
            instalations = instalations.replace(`||${TextQuizStep6_Option_4}`,'')
        }

        this.setState({ 
            question6: instalations,
            nextDisable : this.questionValueValidate()
        })

        sessionStorage.setItem('question_6', instalations)

    }

    componentDidMount()
    {
        if ( this.props.step !== '6' )
        {
            return;
        }
        if ( sessionStorage.getItem('question_6') )
        {
            let options = sessionStorage.getItem('question_6').split('||')
            options.map( value => {
                return document.querySelector(`[value='${value}']`).checked = true
            })
        }
        this.setState({ 
            nextDisable : this.questionValueValidate()
        })
    }

    render()
    {
        if ( this.props.step !== '6' )
        {
            return null;
        }

        return (
            <>
               <section className="page page-quiz" data-step={this.props.step}>
                   <span className="progress"></span>
                   <div className="container">
                       <div className="row">
                            <div className="col-md-7">

                                <h2>
                                    {TextQuizStep6Title}
                                </h2>

                                <div>
                                    <input 
                                        type="checkbox"
                                        name="question_6"
                                        value={TextQuizStep6_Option_0}
                                        id="question_6_a"
                                        onChange={this.handleChange}
                                    />
                                    <label htmlFor="question_6_a">
                                        {TextQuizStep6_Option_0}
                                    </label>
                                </div>

                                <div>
                                    <input 
                                        type="checkbox"
                                        name="question_6"
                                        value={TextQuizStep6_Option_1}
                                        id="question_6_b"
                                        onChange={this.handleChange}
                                    />
                                    <label htmlFor="question_6_b">
                                        {TextQuizStep6_Option_1}
                                    </label>
                                </div>

                                <div>
                                    <input 
                                        type="checkbox"
                                        name="question_6"
                                        value={TextQuizStep6_Option_2}
                                        id="question_6_c"
                                        onChange={this.handleChange}
                                    />
                                    <label htmlFor="question_6_c">
                                        {TextQuizStep6_Option_2}
                                    </label>
                                </div>
                                
                                <div>
                                    <input 
                                        type="checkbox"
                                        name="question_6"
                                        value={TextQuizStep6_Option_3}
                                        id="question_6_d"
                                        onChange={this.handleChange}
                                    />
                                    <label htmlFor="question_6_d">
                                        {TextQuizStep6_Option_3}
                                    </label>
                                </div>

                                <div>
                                    <input 
                                        type="checkbox"
                                        name="question_6"
                                        value={TextQuizStep6_Option_4}
                                        id="question_6_e"
                                        onChange={this.handleChange}    
                                    />
                                    <label htmlFor="question_6_e">
                                        {TextQuizStep6_Option_4}
                                    </label>
                                </div>

                                <div className="mt-5"></div>

                                <QuizNav 
                                    step={this.props.step} 
                                    nextDisable={this.state.nextDisable} 
                                    prevClick={this.props.prevClick} 
                                    nextClick={this.props.nextClick} />
                            </div>
                            <div className="col-md-5 illustration illustration-6">
                                <img src={ilustration} alt="" />
                            </div>

                        </div>
                   </div>
               </section>
            </>
        )
    }
}

export default QuizStep6