import React from 'react'
import {Helmet} from "react-helmet";

import { 
    TextRegulations,
    TextPageRegulationsTitle,
    TextRegulationsDescription
} from './../config/Text';

function Regulations()
{
    return (
        <>
        <Helmet>
            <title>{TextPageRegulationsTitle}</title>
            <meta name="description" content={TextRegulationsDescription} />
        </Helmet>
        <section className="page page-privacy-policy">
            <div class="container">
                <div className="row">
                    <TextRegulations />
                </div>
            </div>
        </section>
        </>
    )
}

export default Regulations;