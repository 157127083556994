import React from 'react'
import {Helmet} from "react-helmet";

import { 
    TextPrivacyPolicy,
    TextPagePrivacyPolicyTitle,
    TextPagePrivacyPolicyDescription
} from './../config/Text';

function PrivacyPolicy()
{
    return (
        <>
        <Helmet>
            <title>{TextPagePrivacyPolicyTitle}</title>
            <meta name="description" content={TextPagePrivacyPolicyDescription} />
        </Helmet>
        <section className="page page-privacy-policy">
            <div class="container">
                <div className="row">
                    <TextPrivacyPolicy />
                </div>
            </div>
        </section>
        </>
    )
}

export default PrivacyPolicy;