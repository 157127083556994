import React from 'react'
import {Helmet} from "react-helmet";

import Banner from '../components/Banner';
import Newsletter from '../components/Newsletter';
import UserEconomy from '../components/UserEconomy';
import CallToCalculate from '../components/CallToCalculate';
import InterestingApartments from '../components/InterestingApartments';

import {
    TextPageHomeTitle,
    TextPageHomeDescription,
} from '../config/Text'

import axios from 'axios'


class Home extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = 
        {
            apartments : null
        }
    }
    componentDidMount()
    {
        let api = new URL(process.env.REACT_APP_MIESZKAY_API_URL)
        api.pathname = 'get_random_apartments'
        let self = this
        axios.post(api.href)
            .then( response => {

                let keys = Object.keys(response.data)


                if( keys.length === 3 )
                {
                    self.setState({ apartments : [
                        response.data[keys[0]],
                        response.data[keys[1]],
                        response.data[keys[2]]
                    ]})
                }
            })
    }
    render()
    {
        return (
            <>
            <Helmet>
                <title>{TextPageHomeTitle}</title>
                <meta name="description" content={TextPageHomeDescription} />
            </Helmet>
            <Banner />
            <CallToCalculate />
            <UserEconomy />
            <InterestingApartments apartments={this.state.apartments} />
            <Newsletter />
            </>
        )
    } 
}

export default Home;